<template>
    <div class="recuring-appts">
        <div class="flex bottom-15">
            <Dropdown
                class="block weekly-monthly"
                name="frequency"
                id="frequency"
                v-model="rRule.freq"
                label="Repeat"
                :options="frequencyOptions"
                disableEmpty
            />

            <label class="flex center font-14 bottom-0">
                <span class="nowrap right-5 left-5">every</span>
                <input class="repeat-weeks right-5 block" type="number" @input="handleRRuleNumChange($event, 'interval')" :value="rRule.interval" min="1"/>
                <span class="block right-5">{{ repeatEveryLabel }}</span>
                <div>&nbsp;on&nbsp;</div>
            </label>

            <div class="days-of-week" v-if="rRule.freq === 'WEEKLY'">
                <Checkbox id="SU" @input="handleWeekdayChange(0)" label="S" :value="isChecked(0)" />
                <Checkbox id="MO" @input="handleWeekdayChange(1)" label="M" :value="isChecked(1)" />
                <Checkbox id="TU" @input="handleWeekdayChange(2)" label="T" :value="isChecked(2)" />
                <Checkbox id="WE" @input="handleWeekdayChange(3)" label="W" :value="isChecked(3)" />
                <Checkbox id="TH" @input="handleWeekdayChange(4)" label="T" :value="isChecked(4)" />
                <Checkbox id="FR" @input="handleWeekdayChange(5)" label="F" :value="isChecked(5)" />
                <Checkbox id="SA" @input="handleWeekdayChange(6)" label="S" :value="isChecked(6)" />
            </div>
        </div>

        <div class="flex center bottom-10" v-if="rRule.freq === 'MONTHLY'">
            <div class="flex center">
                <label class="flex center font-14 right-20 bottom-0">
                    <input class="right-5 color" type="radio" id="DAY_AND_WEEK" value="DAY_AND_WEEK" v-model="monthlyIntervalType" />
                    <div class="left-5">Day and Week</div>
                </label>
                <label class="flex center font-14 right-20 bottom-0">
                    <input class="right-5 color" type="radio" id="DAY_AND_WEEK" value="DAY_OF_MONTH" v-model="monthlyIntervalType" />
                    <div class="left-5">Day of Month</div>
                </label>
            </div>

            <div class="flex center weeks-monthly" v-if="monthlyIntervalType === 'DAY_AND_WEEK'">
                <Dropdown
                    label=""
                    name="weeks_monthly"
                    id="weeks_monthly"
                    v-model="rRule.bysetpos"
                    :options="weekDropdownOptions"
                    disableEmpty
                />
                <div class="left-10 right-10">week on</div>
                <Dropdown
                    label=""
                    name="weekdays_monthly"
                    id="weekdays_monthly"
                    :value="rRule.byweekday[0]"
                    @change="handleMonthlyWeekdayChange"
                    :options="weekdayDropdownOptions"
                    disableEmpty
                />
            </div>

            <label class="bottom-0" v-if="monthlyIntervalType === 'DAY_OF_MONTH'">
                <!-- Date -->
                <input
                    type="number"
                    @input="handleRRuleNumChange($event, 'bymonthday', 31)"
                    :value="rRule.bymonthday"
                    min="1"
                />
                <span v-if="rRule.bymonthday > 28">
                    <span v-if="module">{{ module }}</span><template v-else>Appointments</template> will not be created for months shorter than {{ rRule.bymonthday }} days
                </span>
            </label>
        </div>

        <div class="flex center">
            <Dropdown class="ends flex center right-10" name="end" id="end" v-model="endType" label="Ends" :options="endTypeOptions" disableEmpty />

            <label class="margin-0 flex center font-14" v-if="endType === 'COUNT'">

                <input type="number" @input="handleRRuleNumChange($event, 'count', 30)" :value="rRule.count" min="1" max="30"/>
                <span class="left-5">visit(s).</span>
            </label>

            <label class="margin-0" v-if="endType === 'UNTIL'">
                <!-- Until -->
                <input type="date" @input="handleUntilChange" :value="rRule.until" :min="minUntil" :max="maxUntil" />
            </label>
        </div>

        <p class="top-10 bottom-0" :class="{ 'dark-red': !isValidRule }">{{ ruleText }}</p>
    </div>
</template>

<script>
    import dayjs from '@/util/dayjs';
    import { RRule } from 'rrule';

    export default {
        name: 'RecurrenceEditor',
        props: {
            startDate: {
                type: [String, Date]
            },
            initial: {
                type: Object
            },
            // This is to allow parent components to suppress the display that counts the number of appts in
            // the recurrence rule. This count is relatively expensive and was causing performance issues
            // when closing the EventDetailModal.
            suppressCount: {
                type: Boolean,
                default: false,
            },
            module: {
                type: String
            }
        },
        data() {
            return {
                rRule: {
                    dtstart: typeof this.startDate === 'string' ? new Date(this.startDate) : this.startDate,
                    interval: 1,
                    freq: 'WEEKLY',
                    count: 1,
                    byweekday: [this.startDate ? dayjs(this.startDate).day() : 0]
                },
                frequencyOptions: [
                    { text: 'Weekly', value: 'WEEKLY' },
                    { text: 'Monthly', value: 'MONTHLY' }
                ],
                endType: 'COUNT',
                endTypeOptions: [
                    { text: 'After', value: 'COUNT' },
                    { text: 'On Date', value: 'UNTIL' }
                ],
                monthlyIntervalType: '',
                weekDropdownOptions: [
                    { text: 'First', value: 1 },
                    { text: 'Second', value: 2 },
                    { text: 'Third', value: 3 },
                    { text: 'Fourth', value: 4 },
                    { text: 'Last', value: -1 }
                ],
                weekdayDropdownOptions: [
                    { text: 'Sunday', value: 0 },
                    { text: 'Monday', value: 1 },
                    { text: 'Tuesday', value: 2 },
                    { text: 'Wednesday', value: 3 },
                    { text: 'Thursday', value: 4 },
                    { text: 'Friday', value: 5 },
                    { text: 'Saturday', value: 6 }
                ]
            };
        },
        computed: {
            frequency() {
                return this.rRule.freq;
            },
            repeatEveryLabel() {
                switch (this.rRule.freq) {
                    case 'WEEKLY':
                        return 'week(s)';
                    case 'MONTHLY':
                        return 'month(s)';
                    case 'YEARLY':
                    default:
                        return 'Year(s)';
                }
            },
            minUntil() {
                return dayjs(this.startDate).format('YYYY-MM-DD');
            },
            maxUntil() {
                switch (this.rRule.freq) {
                    case 'WEEKLY':
                        return dayjs(this.startDate)
                            .add(26, 'weeks')
                            .format('YYYY-MM-DD');
                    case 'MONTHLY':
                        return dayjs(this.startDate)
                            .add(24, 'months')
                            .format('YYYY-MM-DD');
                    case 'YEARLY':
                    default:
                        return dayjs(this.startDate)
                            .add(5, 'years')
                            .format('YYYY-MM-DD');
                }
            },
            ruleText() {
                if (this.suppressCount) {
                    return '';
                }

                if (this.rRule.interval !== undefined && this.rRule.interval !== null && isNaN(this.rRule.interval)) {
                    return 'Invalid interval';
                }

                if (this.rRule.count !== undefined && this.rRule.count !== null && isNaN(this.rRule.count)) {
                    return 'Invalid number of sessions';
                }

                let freqConversion = 0;

                if (this.rRule.freq === 'MONTHLY') {
                    freqConversion = 1;
                }

                if (this.rRule.freq === 'WEEKLY') {
                    freqConversion = 2;
                }

                try {
                    const ruleOptions = { ...this.rRule, freq: freqConversion };

                    if (ruleOptions.until) {
                        ruleOptions.until = dayjs
                            .utc(ruleOptions.until)
                            .add(23, 'hours')
                            .add(59, 'minutes')
                            .add(59, 'seconds')
                            .toDate();
                    }

                    if (ruleOptions.byweekday) {
                        ruleOptions.byweekday = ruleOptions.byweekday.map((val) => {
                            if (val === 0) {
                                return 6;
                            }

                            return val - 1;
                        });

                        ruleOptions.byweekday.sort((a, b) => {
                            if (a === 6) {
                                return -1;
                            }

                            if (b === 6) {
                                return 1;
                            }

                            return a - b;
                        });
                    }

                    const rule = new RRule(ruleOptions);
                    const apptCount = rule.all().length;
                    let rawText = `${ this.module ? this.module : 'Appointment' } will occur ${rule.toText()}.`;

                    rawText = rawText.replace(
                        /for \d+ times{0,1}/g,
                        `with ${apptCount} total ${ this.module ? this.module.toLowerCase() : 'appointment' } ${apptCount > 1 ? 's' : ''}`
                    );

                    if (rawText.includes(' until ')) {
                        rawText = rawText.replace('.', '');
                        rawText += `, with ${apptCount} total ${ this.module ? this.module.toLowerCase() : 'appointment' } ${apptCount > 1 ? 's' : ''}.`;
                    }

                    if (ruleOptions.bysetpos) {
                        const posMap = {
                            '-1': 'last',
                            '1': 'first',
                            '2': 'second',
                            '3': 'third',
                            '4': 'fourth'
                        };

                        rawText = rawText.replace(/ on /g, ` on the ${posMap[ruleOptions.bysetpos]} `);
                    }

                    return rawText;
                } catch (err) {
                    return '';
                }
            },
            isValidRule() {
                return !this.ruleText.toLowerCase().includes('invalid');
            },
            originalWeekday() {
                return dayjs(this.startDate).day();
            },
            originalMonthday() {
                return dayjs(this.startDate).date();
            },
            originalWeekOfMonth() {
                const week = Math.ceil(this.originalMonthday / 7);

                return week === 5 ? -1 : week;
            }
        },
        methods: {
            handleRRuleNumChange(e, prop, max) {
                const num = parseInt(e.target.value);
                const titleCasedProp =
                    prop === 'bymonthday'
                        ? 'Date'
                        : prop
                              .split('')
                              .map((letter, idx) => {
                                  if (idx === 0) {
                                      return letter.toUpperCase();
                                  }

                                  return letter;
                              })
                              .join('');
                const prevCount = this.rRule[prop];

                if (isNaN(num) && e.target.value !== '') {
                    this.$set(this.rRule, prop, num);
                    this.$set(this.rRule, prop, prevCount);
                    this.$toasted.error(`${titleCasedProp} must be a number`);
                } else if (max !== undefined && num > max) {
                    this.$set(this.rRule, prop, num);
                    this.$set(this.rRule, prop, prevCount);
                    this.$toasted.error(`${titleCasedProp} cannot be more than ${max}`);
                } else if (num < 1) {
                    this.$set(this.rRule, prop, num);
                    this.$set(this.rRule, prop, prevCount);
                    this.$toasted.error(`${titleCasedProp} cannot be less than 1`);
                } else if (!Number.isInteger(num)) {
                    this.$set(this.rRule, prop, num);
                    this.$set(this.rRule, prop, prevCount);
                    this.$toasted.error(`${titleCasedProp} is not valid`);
                } else {
                    this.$set(this.rRule, prop, num);
                }
            },
            handleUntilChange(e) {
                this.$set(this.rRule, 'until', e.target.value);
            },
            async handleWeekdayChange(val) {
                const weekdaySet = new Set(this.rRule.byweekday);

                if (weekdaySet.has(val)) {
                    weekdaySet.delete(val);
                } else {
                    weekdaySet.add(val);
                }

                this.$set(this.rRule, 'byweekday', [...weekdaySet]);
            },
            handleMonthlyWeekdayChange(val) {
                this.rRule.byweekday = [parseInt(val)];
            },
            isChecked(val) {
                const weekdaySet = new Set(this.rRule.byweekday);
                return weekdaySet.has(val);
            },
            async reset(newFrequency = 'WEEKLY') {
                await this.$nextTick();

                this.$set(this.rRule, 'freq', newFrequency);
                this.$set(this.rRule, 'interval', 1);
                this.$set(this.rRule, 'count', 1);
                this.$set(this.rRule, 'byweekday', [dayjs(this.startDate).day()]);
                this.endType = 'COUNT';
                this.monthlyIntervalType = '';

                if (newFrequency === 'MONTHLY' || newFrequency === 'YEARLY') {
                    this.monthlyIntervalType = 'DAY_AND_WEEK';
                }
            },
            initialize(value) {
                if (!value || Object.keys(value).length === 0) {
                    this.rRule = {
                        dtstart: typeof this.startDate === 'string' ? new Date(this.startDate) : this.startDate,
                        interval: 1,
                        freq: 'WEEKLY',
                        count: 1,
                        byweekday: [this.startDate ? dayjs(this.startDate).day() : 0]
            }
                    this.endType = 'COUNT';
                    return;
                }
                if (value.freq) {
                    if (value.until) {
                        // this.endType = 'UNTIL';
                        this.$set(this, 'endType', 'UNTIL');
                        delete value.count;
                    } else {
                        this.endType = 'COUNT';
                    }
                    this.$set(this, 'rRule', value);
                }
            },
        },
        created() {
            this.initialize(this.initial);
        },
        watch: {
            endType(newVal) {
                if (newVal === 'COUNT') {
                    this.$set(this.rRule, 'until', undefined);
                    this.$set(this.rRule, 'count', 1);
                }

                if (newVal === 'UNTIL') {
                    this.$set(this.rRule, 'count', undefined);
                    this.$set(
                        this.rRule,
                        'until',
                        dayjs(this.rRule.until, 'YYYY-MM-DD').format('YYYY-MM-DD') ||
                            dayjs(this.startDate).format('YYYY-MM-DD')
                    );
                }
            },
            monthlyIntervalType(newVal) {
                if (newVal === '') {
                    this.$set(this.rRule, 'bymonthday', undefined);
                    this.$set(this.rRule, 'byweekday', [this.originalWeekday]);
                    this.$set(this.rRule, 'bysetpos', undefined);
                }

                if (newVal === 'DAY_AND_WEEK') {
                    this.$set(this.rRule, 'bymonthday', undefined);
                    this.$set(this.rRule, 'byweekday', [this.originalWeekday]);
                    this.$set(this.rRule, 'bysetpos', this.originalWeekOfMonth);
                }

                if (newVal === 'DAY_OF_MONTH') {
                    this.$set(this.rRule, 'bymonthday', this.originalMonthday);
                    this.$set(this.rRule, 'byweekday', undefined);
                    this.$set(this.rRule, 'bysetpos', undefined);
                }
            },
            'rRule.freq'(newVal) {
                this.reset(newVal);
            },
            'rRule.bysetpos'(newVal) {
                if (typeof newVal === 'string') {
                    this.$set(this.rRule, 'bysetpos', parseInt(newVal));
                }
            },
            rRule: {
                deep: true,
                handler(newVal) {
                    const sanitizedPayload = {};

                    Object.keys(this.rRule).forEach((key) => {
                        if (this.rRule[key] !== undefined) {
                            if (key === 'until') {
                                sanitizedPayload[key] = dayjs(this.rRule[key])
                                    .add(23, 'hours')
                                    .add(59, 'minutes')
                                    .add(59, 'seconds')
                                    .toDate();
                            } else {
                                sanitizedPayload[key] = this.rRule[key];
                            }
                        }
                    });

                    this.$emit('rRule', sanitizedPayload);
                }
            },
            startDate(newVal) {
                this.rRule.dtstart = typeof newVal === 'string' ? new Date(newVal) : newVal;
                this.rRule.byweekday = [dayjs(newVal).day()];
            },
            initial(value) {



                this.initialize(value);
            },
            ruleText() {
                this.$emit('validityUpdate', this.isValidRule);
            }
        }
    };
</script>